import React, { useState, useEffect } from "react";
import "./App.css";
import { TopicContext } from "./components/TopicContext";
import { NavBar } from "./components/NavBar";
import { IntroSlide } from "./components/IntroSlide";
import Media from "./components/mediaContentWindow";
import SplashScreen from "./components/SplashScreen";
import http from "./lib/http"

const App = () => {
  const [topicType, setTopicType] = useState("gut-brain-axis");
  const [topicChosen, setTopicChosen] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [slide, setSlide] = useState("");
  // const [introText, setIntroText] = useState("");
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/slides/`);
      setSlide(data);
      // console.log(slide);
    }
    fetchData();
    return () => {
    };
  }, [slide, topicType]);
  return (
    <TopicContext.Provider
      value={{
        topicType,
        setTopicType,
        topicChosen,
        setTopicChosen,
        showSplash,
        setShowSplash,
      }}
    >
      {topicChosen ? (
        <div className="app">
          <NavBar />
          <Media {...slide}/>
          
        </div>
      ) : (
        <div className="app">
          <SplashScreen />
          <IntroSlide />
        </div>
      )}
    </TopicContext.Provider>
  );
};
export default App;
// export default App;
